import { Picture } from '~/components/ui/Picture';

export const Logo: React.FC<{
	isRebrandEnabled?: boolean;
	resourcesUrl: string;
}> = ({ isRebrandEnabled, resourcesUrl }) => {
	const logoPath = `${resourcesUrl}/assets/img/${
		isRebrandEnabled ? 'rebrand/oc-logo.png' : 'oc-logo.svg'
	}`;

	const logoWidth = isRebrandEnabled ? 213 : 214;
	const logoHeight = isRebrandEnabled ? 34 : 40;

	return (
		<Picture
			altText="Oddschecker Logo"
			height={logoHeight}
			svgSrc={`${logoPath}`}
			width={logoWidth}
		/>
	);
};
